import * as React from "react";

import { cn } from "~/lib/utils";

import { ErrorMessage } from "../error-message";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  containerClassName?: string;
  error?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, containerClassName, error, ...props }, ref) => {
    return (
      <div className={cn("flex flex-col", containerClassName)}>
        <textarea
          className={cn(
            "flex min-h-[80px] w-full rounded-md border border-slate-200 bg-white px-3 py-2 text-sm ring-offset-white placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            {
              "border-red-600": error,
            },
            className
          )}
          ref={ref}
          {...props}
        />
        <ErrorMessage error={error} />
      </div>
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
